import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { DataSourceSettings } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Permissions } from 'app/core/components/AccessControl';
import { UpgradeBox } from 'app/core/components/Upgrade/UpgradeBox';
import { contextSrv } from 'app/core/core';
import { highlightTrial } from 'app/features/admin/utils';
import { useDataSourceRights } from 'app/features/datasources/state';
import { loadDataSource, loadDataSourceMeta, updateDataSource } from 'app/features/datasources/state/actions';

import { TeamLBACEditor } from '../teamLBAC/TeamLBACEditor';
import { hasLBACSupport } from '../teamLBAC/utils';
import {
  AccessControlAction as EnterpriseActions,
  EnterpriseStoreState,
  TeamHTTPHeaderJsonData,
  TeamHeaders,
} from '../types';

type ExternalProps = { uid: string };

function mapStateToProps(state: EnterpriseStoreState, props: ExternalProps) {
  const { uid } = props;
  return {
    resourceId: uid,
    dataSourceConfig: state.dataSources.dataSource,
  };
}

const mapDispatchToProps = {
  loadDataSource,
  loadDataSourceMeta,
  updateDataSource,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = ConnectedProps<typeof connector>;

// The RBAC version for data source permissions
const DataSourcePermissions = ({
  resourceId,
  dataSourceConfig,
  loadDataSource,
  loadDataSourceMeta,
  updateDataSource,
}: Props) => {
  useEffect(() => {
    loadDataSource(resourceId);
  }, [resourceId, loadDataSource]);

  const canSetPermissions = contextSrv.hasPermissionInMetadata(
    EnterpriseActions.DataSourcesPermissionsWrite,
    dataSourceConfig
  );

  useEffect(() => {
    // Initialize DS metadata on page load to populate tab navigation
    loadDataSource(resourceId as any).then(loadDataSourceMeta);
  }, [loadDataSource, loadDataSourceMeta, resourceId]);

  const onTeamHeadersUpdate = (teamHttpHeaders: TeamHeaders) => {
    const newDsSettings: DataSourceSettings<TeamHTTPHeaderJsonData> = {
      ...dataSourceConfig,
      jsonData: { ...dataSourceConfig.jsonData, teamHttpHeaders },
    };
    return updateDataSource({ ...newDsSettings });
  };

  const { readOnly, hasWriteRights } = useDataSourceRights(dataSourceConfig.uid);
  const showTeamLBAC = config.featureToggles.teamHttpHeaders && hasLBACSupport(dataSourceConfig);
  const readOnlyLBAC = readOnly || !canSetPermissions || !hasWriteRights;

  return (
    <>
      {highlightTrial() && (
        <UpgradeBox
          featureId={'data-source-permissions'}
          eventVariant={'trial'}
          featureName={'data source permissions'}
          text={'Enable data source permissions for free during your trial of Grafana Pro.'}
        />
      )}
      <Permissions resource="datasources" resourceId={resourceId} canSetPermissions={canSetPermissions} />

      {showTeamLBAC && (
        <TeamLBACEditor
          teamHeaders={(dataSourceConfig.jsonData as TeamHTTPHeaderJsonData)?.teamHttpHeaders}
          dataSourceConfig={dataSourceConfig}
          readOnly={readOnlyLBAC}
          onTeamHeadersUpdate={onTeamHeadersUpdate}
        />
      )}
    </>
  );
};

export default connector(DataSourcePermissions);
