import React from 'react';

import { Button, Input, Form, InputControl, Field, Stack } from '@grafana/ui';
import { CloseButton } from 'app/core/components/CloseButton/CloseButton';
import { TeamPicker } from 'app/core/components/Select/TeamPicker';
import { Trans } from 'app/core/internationalization';

import { validateLBACRule } from './utils';

export interface LBACFormData {
  team: number;
  rule: string;
}

interface AddTeamLBACFormProps {
  onSubmit: (data: LBACFormData) => void;
  onClose: () => void;
}

export const AddTeamLBACForm = ({ onSubmit, onClose }: AddTeamLBACFormProps) => {
  return (
    <div className="cta-form" aria-label="Permissions slider">
      <CloseButton onClick={onClose} />
      <h5>Add LBAC Rule</h5>

      <Form name="addTeamLBAC" maxWidth="none" onSubmit={onSubmit}>
        {({ register, control, errors }) => (
          <Stack gap={1} direction="row">
            <Field label="Team">
              <InputControl
                render={({ field: { onChange, ref, ...field } }) => (
                  <TeamPicker {...field} onSelected={(t) => onChange(t.value?.id)} />
                )}
                control={control}
                name={`team`}
                rules={{ required: { value: true, message: 'Required' } }}
              />
            </Field>
            <Field label="Rule">
              <Input
                type="text"
                {...register('rule', {
                  required: 'Rule is required',
                  validate: (val) => (validateLBACRule(val) ? true : 'Invalid LBAC rule syntax'),
                })}
                width={30}
                invalid={!!errors.rule}
              />
            </Field>
            <Field label="">
              <Button type="submit">
                <Trans i18nKey="access-control.add-permissions.save">Save</Trans>
              </Button>
            </Field>
          </Stack>
        )}
      </Form>
    </div>
  );
};
