import { DataSourceSettings, DataSourceJsonData } from '@grafana/data';

export const DataSourcesWithLBACSupport = ['loki'];

export const LBACHTTPHeaderName = 'X-Prom-Label-Policy';

export function hasLBACSupport(ds: DataSourceSettings<DataSourceJsonData, {}>): boolean {
  return !!ds.basicAuth && DataSourcesWithLBACSupport.includes(ds.type);
}

export function formatLBACRule(labelSelector: string, tenantId: string) {
  const pattern = /\{{0,1}([^\{\}]*)\}{0,1}/;
  const res = pattern.exec(labelSelector);
  if (res && res.length > 1) {
    return `${tenantId}:{ ${res[1].trim()} }`;
  }
  return '';
}

export function extractLBACRule(rule: string) {
  const pattern = /\w+\:\{{0,1}([^\{\}]*)\}{0,1}/;
  const res = pattern.exec(rule);
  if (res && res.length > 1) {
    return res[1].trim();
  }
  return '';
}

export const validateLBACRule = (str: string): boolean => {
  if (!str) {
    return false;
  }
  const pattern = /^{*\s*(?:\s*\w+\s*(?:=|!=|=~|!~)\s*\"[^\"]+\"\s*,*)+\s*}*$/;
  return pattern.test(str.trim());
};
